/* styles/landingPages.css */
/* Common styles for all landing pages */

/* Section Titles Styling - Increased specificity */
.landing-page .landing-section-title,
.landing-page .about-section-title,
.landing-page .contact-section-title {
  font-size: 20px !important;
  font-weight: bold !important;
  color: var(--primary-color) !important;
  margin-bottom: 12px !important;
}

/* Left-aligned section title */
.landing-page .landing-section-title.text-left,
.landing-page .about-section-title {
  text-align: left !important;
}

/* Center-aligned section title */
.landing-page .landing-section-title.text-center,
.landing-page .contact-section-title {
  text-align: center !important;
}

/* Special class to override MUI Typography styles */
.fusesoul-heading.MuiTypography-root {
  font-size: 20px !important;
  font-weight: bold !important;
  color: var(--primary-color) !important;
  margin-bottom: 12px !important;
}

/* Background Animation Keyframes */
@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Hero Section Base Styles */
.landing-hero-section {
  position: relative;
  height: 55vh;
  overflow: hidden;
  margin-bottom: 0; /* Ensure no bottom margin on hero */
  background-color: #22489E; /* Fallback color that matches brand */
  background-size: 300% 300%;
  animation: gradientFlow 20s ease infinite; /* Subtle flowing animation */
}

.landing-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.8s ease-in-out; /* Slower transition for smoother effect */
  will-change: opacity; /* Optimize for transitions */
  transform: translateZ(0); /* Force GPU acceleration */
  backface-visibility: hidden; /* Prevent flickering in some browsers */
  animation: fadeIn 0.8s ease-in-out 0.3s forwards; /* Delay image appearance by 0.3s */
  opacity: 0; /* Start with opacity 0 */
}

/* Image loading states */
.landing-hero-image.loading {
  opacity: 0; /* Completely transparent while loading to show animated background */
}

.landing-hero-image.loaded {
  /* The fadeIn animation will handle the transition to opacity 1 */
}

.landing-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4); /* Slightly less dark overlay */
  color: #fff;
  text-align: center;
  z-index: 2; /* Ensure overlay is above the image */
}

.landing-hero-title {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Add shadow for better readability */
  animation: fadeIn 0.8s ease-in-out; /* Fade in the title */
}

.landing-hero-subtitle {
  font-size: 1.5rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); /* Add shadow for better readability */
  animation: fadeIn 0.8s ease-in-out 0.2s forwards; /* Fade in the subtitle with a slight delay */
  opacity: 0;
}

/* Ensure consistent section spacing */
.landing-section {
  padding-top: 2.5rem; /* Reduced from 4rem */
  padding-bottom: 4rem;
}

/* Specific adjustment for the first section after hero */
.landing-page .landing-section:first-of-type {
  padding-top: 2rem;
  margin-top: 0; /* Ensure no top margin */
}

/* Reduce margin between hero and content section */
.landing-hero-section + .landing-section,
.about-hero-section + .section,
.contact-hero-section + .section {
  margin-top: 0;
  padding-top: 1.5rem;
}

/* Specific class for content immediately after hero */
.content-after-hero {
  padding-top: 1rem !important;
  margin-top: 0 !important;
}

/* About page specific hero background - Rich blue to purple gradient */
.about-hero-section {
  background-color: #22489E; /* Fallback color that matches brand */
  background-image: linear-gradient(135deg, 
    #22489E, /* Deep blue */
    #3a5fcc, /* Medium blue */
    #7b5fd9, /* Blue-purple */
    #9b4fd9, /* Medium purple */
    #7b5fd9, /* Blue-purple */
    #3a5fcc, /* Medium blue */
    #22489E  /* Deep blue */
  ); 
}

/* Contact page specific hero background - Purple to blue gradient */
.contact-hero-section {
  background-color: #22489E; /* Fallback color that matches brand */
  background-image: linear-gradient(135deg, 
    #9b4fd9, /* Medium purple */
    #7b5fd9, /* Blue-purple */
    #5a7fe7, /* Light blue */
    #4a6fd7, /* Medium blue */
    #5a7fe7, /* Light blue */
    #7b5fd9, /* Blue-purple */
    #9b4fd9  /* Medium purple */
  );
}

@media (min-width: 768px) {
  .landing-section {
    padding-top: 3.5rem; /* Reduced from 6rem */
    padding-bottom: 6rem;
  }
  
  /* Specific adjustment for the first section after hero */
  .landing-page .landing-section:first-of-type {
    padding-top: 3rem;
  }
  
  /* Reduce margin between hero and content section for larger screens */
  .landing-hero-section + .landing-section,
  .about-hero-section + .section,
  .contact-hero-section + .section {
    padding-top: 2rem;
  }
  
  /* Specific class for content immediately after hero */
  .content-after-hero {
    padding-top: 1.5rem !important;
  }
} 