/* index.css */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

html {
  overflow-x: hidden;
  width: 100%;
}

.App-content {
  padding-top: 65px !important; /* Ensure this has higher specificity */
  overflow-x: hidden;
}


/*.landing-page {
  margin-top: 0 !important; /* Override for landing pages - the class is in the global.css
}
*/


/* Ensure other global styles are correctly set */
