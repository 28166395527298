/* Mobile-specific styles */

/* General mobile optimizations */
@media (max-width: 768px) {
  /* Increase touch targets for better mobile usability */
  button, 
  .MuiButton-root,
  .MuiIconButton-root {
    min-height: 44px;
    min-width: 44px;
  }
  
  /* Increase font size for better readability on small screens */
  body {
    font-size: 16px;
  }
  
  /* Adjust input fields for mobile */
  .MuiTextField-root input,
  .MuiOutlinedInput-input {
    font-size: 16px; /* Prevents iOS zoom on focus */
    padding: 14px 12px;
  }
  
  /* Optimize form layouts */
  form {
    width: 100%;
  }
  
  /* Ensure buttons are full width on mobile */
  .full-width-mobile {
    width: 100% !important;
  }
  
  /* Add more space between form elements */
  .MuiFormControl-root {
    margin-bottom: 16px;
  }
  
  /* Optimize login/signup containers */
  .auth-container {
    padding: 16px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
}

/* Mobile-specific login page enhancements */
.mobile-welcome-banner {
  background: linear-gradient(135deg, #22489E, #3a5fcc);
  color: white;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-welcome-banner h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.mobile-welcome-banner p {
  margin: 8px 0 0;
  font-size: 0.875rem;
  opacity: 0.9;
}

/* Mobile-specific animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.mobile-fade-in {
  animation: fadeIn 0.5s ease-in-out;
} 