/* AvatarCreationPage.css */

.avatar-creation-container {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
  background-color: #f5f5f5;
}

.avatar-display {
  margin: 0 auto 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  width: 100%;
}

.avatar-display svg {
  margin: 0 auto;
  display: block;
}

.message {
  margin-top: 10px;
  color: #666;
  font-style: italic;
}

.options-grid {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.features-carousel {
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
  gap: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.features-carousel::-webkit-scrollbar {
  display: none;
}

.feature-card {
  min-width: 200px;
  margin-right: 16px;
  position: relative;
}

.feature-card h6 {
  text-transform: capitalize;
}

.feature-title {
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 4px !important;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 1rem !important;
}

.feature-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #1976d2;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
  font-size: 0.8rem;
}

.feature-description {
  margin-bottom: 12px;
  color: #666;
}

.options-carousel-container {
  position: relative;
  padding: 0 20px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
}

.carousel-arrow:hover {
  background-color: #1976d2;
  color: white;
}

.carousel-arrow.left {
  left: 5px;
}

.carousel-arrow.right {
  right: 5px;
}

.options-carousel {
  display: flex;
  overflow-x: auto;
  padding: 5px 0;
  gap: 12px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.options-carousel::-webkit-scrollbar {
  display: none;
}

.option-card {
  min-width: 120px;
  text-align: center;
}

.option-card .MuiTypography-root {
  text-transform: capitalize;
  font-size: 0.8rem;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 2px;
  word-break: break-word;
  margin-bottom: 0;
  padding: 2px 0;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .avatar-creation-container {
    padding: 10px;
  }

  .feature-card {
    min-width: 160px;
    margin-right: 12px;
  }

  .feature-card .MuiCardContent-root {
    padding-bottom: 8px !important;
  }

  .mini-avatar-preview {
    width: 80px;
    height: 80px;
  }

  .feature-number {
    width: 18px;
    height: 18px;
    font-size: 0.75rem;
    top: 8px;
    left: 8px;
  }
  
  .feature-title {
    margin-left: 0 !important;
    padding-top: 8px !important;
    font-size: 0.85rem !important;
    text-align: center !important;
    margin-bottom: 4px !important;
    padding-bottom: 4px !important;
  }
}

.mini-avatar-preview {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(240, 245, 255, 0.5);
}

/* Highlight effect for the feature cards */
.feature-card .mini-avatar-preview {
  box-shadow: 0 0 8px rgba(25, 118, 210, 0.25);
  transition: transform 0.2s, box-shadow 0.2s;
}

.feature-card:hover .mini-avatar-preview {
  transform: scale(1.05);
  box-shadow: 0 0 12px rgba(25, 118, 210, 0.4);
}

.back-button-container {
  margin: 8px 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.back-button {
  padding: 4px 12px !important;
  min-height: 32px !important;
  font-size: 0.875rem !important;
  position: relative;
  justify-content: flex-start !important;
  width: auto;
  max-width: 150px;
  z-index: 2;
}

.category-title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  color: #333;
  text-transform: capitalize;
  pointer-events: none;
  z-index: 1;
}

.options-title {
  margin: 16px 0;
  text-align: center;
  color: #333;
}

.feature-section {
  margin-bottom: 24px;
}

/* Add styles for the selected option text in feature cards */
.feature-card .MuiTypography-caption {
  color: var(--primary-color, #1976d2);
  background-color: rgba(25, 118, 210, 0.05);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  display: inline-block;
}

/* Add a CSS rule for the reset avatar button */
.reset-avatar-button {
  padding: 6px 12px !important;
  font-size: 0.875rem !important;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .back-button {
    max-width: 120px;
    font-size: 0.75rem !important;
    padding: 4px 8px !important;
  }
  
  .back-button svg {
    font-size: 0.75rem !important;
    margin-right: 0.25em !important;
  }
  
  .category-title {
    font-size: 0.9rem;
  }
  
  .reset-avatar-button {
    padding: 3px 8px !important;
    font-size: 0.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: unset !important;
  }
}

/* Mobile-specific styling for feature captions */
@media (max-width: 768px) {
  .feature-card .MuiTypography-caption {
    font-size: 0.7rem;
    padding: 1px 6px;
    margin-top: 0 !important;
    margin-bottom: 6px !important;
  }
}