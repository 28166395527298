/* Challenge page specific styles */

/* Ensure tooltips are visible */
.MuiTooltip-popper,
.MuiTooltip-root,
[role="tooltip"] {
  opacity: 1 !important;
  visibility: visible !important;
  display: block !important;
  pointer-events: auto !important;
}

/* Tooltip styling */
.MuiTooltip-tooltip {
  background-color: rgba(33, 33, 33, 0.9) !important;
  color: white !important;
  font-weight: 500 !important;
  padding: 8px 12px !important;
  font-size: 0.75rem !important;
  border-radius: 4px !important;
  max-width: 200px !important;
  word-wrap: break-word !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  margin: 0 !important;
}

/* Arrow styling */
.MuiTooltip-arrow {
  color: rgba(33, 33, 33, 0.9) !important;
  font-size: 16px !important;
}

/* Make sure the Icon Buttons have proper pointer events */
.challenge-action-button,
.MuiIconButton-root {
  cursor: pointer !important;
  pointer-events: auto !important;
  padding: 8px;
  margin-right: 4px;
}

/* Challenge card styling */
.challenge-card {
  height: auto;
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.challenge-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Progress bar styling */
.challenge-progress-bar {
  height: 10px;
  border-radius: 5px;
  background-color: var(--tertiary-color);
}

.challenge-progress-bar .MuiLinearProgress-bar {
  background-color: var(--primary-color);
}

/* Dialog styling */
.challenge-dialog-title {
  color: var(--primary-color);
  font-weight: 500;
}

/* Emoji picker container */
.emoji-picker-container {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 600px) {
  .challenge-action-button {
    padding: 12px;
  }
  
  .challenge-card {
    min-height: 175px;
  }
  
  .challenge-card:hover {
    transform: none;
  }
  
  .challenge-card:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
} 