/* styles/global.css */
/* Import Tailwind's base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* Custom CSS Variables */
:root {
  --primary-color: #22489E;  /* Royal Blue */
  --secondary-color: #1E90FF;  /* Dodger Blue */
  --tertiary-color: #D7EAF3;  /* Light Blue */
  --soft-light-blue: #f6fbfe;
  --contrast-color: #f44336;
  --text-color: #444;
  --earth-color: #33cc33;
  --water-color: #00b7ff;
  --air-color: #b19cd9;
  --fire-color: #ffb347;

  /* Spacing adjustments */
  --spacing-1: 2px;
  --spacing-2: 4px; 
  --spacing-3: 6px;
  --spacing-4: 8px;
  --spacing-5: 12px;
  --spacing-6: 16px;
  --spacing-8: 24px;
  --spacing-10: 32px;
  --spacing-12: 40px;
  --spacing-16: 56px;
  --spacing-20: 72px;
  --spacing-24: 88px;

  --inspiration-bg: hsl(210 20% 98%);
  --inspiration-fg: hsl(215 25% 27%);
  --inspiration-card-bg: hsl(0 0% 100%);
  --inspiration-card-fg: hsl(215 25% 27%);
  --inspiration-accent: hsl(224 76% 48%);
  --inspiration-accent-hover: hsl(214 82% 51%);
}

/* Resetting default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif; /* Apply Inter font globally */
}

/* Base font styles */
body {
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
  overflow-y: scroll; /* Always show vertical scrollbar */
}

/* Custom keyframes */
@keyframes circle-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}

/* Tailwind customizations */
.bg-soft-light-blue {
  background-color: var(--soft-light-blue);
}

.text-primary-color {
  color: var(--primary-color);
}

.bg-tertiary-color {
  background-color: var(--tertiary-color);
}

.text-secondary-color {
  color: var(--secondary-color);
}

.bg-contrast-color {
  background-color: var(--contrast-color);
}

.text-tertiary-color {
  color: var(--tertiary-color);
}

/* Improved Header Styles */
.header-content {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: var(--spacing-6);
  color: white;
}

.header-avatar {
  width: var(--spacing-12);
  height: var(--spacing-12);
  cursor: pointer;
  border: 2px solid white;
  border-radius: 50%;
}

.header-text {
  font-size: 1.25rem;
  font-weight: 500;
}

.header-subtext {
  font-size: 1rem;
  font-style: italic;
  color: #e3f2fd;
}

/* Adjusting container width for smaller screens */
.header-content {
  @apply flex flex-col sm:flex-row items-center justify-between text-center text-white mb-6 shadow-xl max-h-[150px] sm:space-x-4 space-y-4 sm:space-y-0;
}

.header-text {
  @apply text-left sm:w-2/3 w-full;
}

.header-avatar {
  @apply w-[60px] h-[60px] sm:w-[90px] sm:h-[90px];
}

/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

/* Icon colors */
.text-earth {
  color: var(--earth-color);
}

.text-water {
  color: var(--water-color);
}

.text-air {
  color: var(--air-color);
}

.text-fire {
  color: var(--fire-color);
}

/* Common Button Styles */
.button-common {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color)) !important;
  color: #fff !important;
  border-radius: 4px;
  padding: var(--spacing-3) var(--spacing-5);
  font-weight: normal; 
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button-common:hover {
  opacity: 0.9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Finish and Submit Button Styles */
.finish-submit-button {
  background: linear-gradient(45deg, #7B1FA2, #BA68C8) !important;
  color: #fff !important;
  border: 2px solid #BA68C8;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.finish-submit-button:hover {
  background: linear-gradient(45deg, #A020F0, #C71585) !important;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

/* Add custom styles for landing pages */
.landing-page {
  margin-top: 0 !important;
}

/* General Dialog Styles */
/* Used for dialog headers requiring a blue background, e.g., delete confirmation popups */
.blue-dialog-header {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 12px 24px;
  font-size: 1.2rem;
}

/* Cancel button style for dialogs */
.dialog-cancel-button {
  color: var(--text-secondary-color, #6c757d) !important; /* Use variable or fallback grey */
  text-transform: uppercase !important;
  /* Add other styles if needed to mimic MUI text button */
}

/* Delete button style for dialogs */
.dialog-delete-button {
  background-color: var(--contrast-color, #d32f2f) !important; /* Use variable or fallback red */
  color: white !important;
  text-transform: uppercase !important;
  padding: 6px 16px !important; /* Match MUI contained button padding */
  border-radius: 4px !important; /* Match MUI button radius */
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important; /* Match MUI shadow */
  /* Add other styles if needed */
}

.dialog-delete-button:hover {
  background-color: #c62828 !important; /* Slightly darker red on hover */
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

/* Side Menu Styles */
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #fff;
  padding: var(--spacing-2) 0;
  transition: width 0.3s ease !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  width: var(--spacing-24);
}

.sidebar.open {
  width: 250px;
  justify-content: flex-start;
  transition: width 0.3s ease !important;
}

.sidebar.collapsed {
  width: var(--spacing-24);
  transition: width 0.3s ease !important;
}

.sidebar .nav-list {
  width: 100%;
}

.sidebar li a {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #fff;
  justify-content: center;
}

.sidebar li a:hover {
  background: #f0f0f0;
}

.sidebar li a .links_name {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;
  display: none;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
  display: inline;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #000;
}

.sidebar li i {
  color: #808080;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar li .tooltip {
  position: absolute;
  top: 50%;
  left: calc(100% + 15px);
  transform: translateY(-50%);
  background: #fff;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.4s ease;
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar li .MuiListItemIcon-root {
  min-width: var(--spacing-24);
  width: var(--spacing-24);
  justify-content: center;
  margin-right: 0;
}

.sidebar.open li .MuiListItemIcon-root {
  justify-content: flex-start;
}

.sidebar.collapsed li .MuiListItemIcon-root {
  justify-content: center;
  margin-right: 0;
}

/* This controls the vertical spacing between icons */
.sidebar .MuiListItem-root {
  padding: var(--spacing-3) 0;
}

#menuButton {
  align-self: center;
  margin: var(--spacing-2) 0;
}

.sidebar.open #menuButton {
  align-self: flex-end;
  margin: var(--spacing-2) var(--spacing-2) 0 0;
}

.main-content {
  margin-left: var(--spacing-24);
  flex: 1;
  transition: margin-left 0.3s ease;
  padding: 10px;
  overflow-x: hidden;
  box-sizing: border-box;
}

.sidebar-open .main-content {
  margin-left: 250px; /* Match the expanded sidebar width */
  width: calc(100% - 250px); /* Adjust width when sidebar is open */
}

.sidebar-icon {
  display: flex;
  justify-content: center !important;
  align-items: center;
  width: var(--spacing-24);
  min-width: var(--spacing-24);
  height: 100%;
  transition: none !important;
}

.sidebar.open .sidebar-icon {
  justify-content: flex-start !important;
  width: auto;
  margin-left: var(--spacing-3);
}

/* New Styles for App Pages with Sidebar */
.app-page .main-content {
  margin-left: 64px; /* Match the collapsed sidebar width */
  transition: margin-left 0.3s ease;
  width: calc(100% - 64px); /* Adjust width to account for sidebar */
}

.app-page .sidebar-open .main-content {
  margin-left: 250px; /* Match the expanded sidebar width */
  width: calc(100% - 250px); /* Adjust width when sidebar is open */
}

/* Styles for Landing and Auth Pages */
.landing-page .landing-content {
  margin-left: 0;
  width: 100%;
}

/* Ensure Full Width for Landing Pages */
.landing-page .App-container {
  padding-top: 65px !important;
  width: 100%;
  max-width: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-content,
  .app-page .main-content,
  .sidebar-open .main-content,
  .app-page .sidebar-open .main-content,
  .landing-page .landing-content {
    margin-left: 0;
    width: 100%;
  }
}

/* Responsive Button Styles */
.button-common.compact-button {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem 0.5rem;
}

/* Adjust Compact Button for Smaller Screens */
@media (max-width: 768px) {
  .button-common.compact-button {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
}

@font-face {
  font-family: 'Nunito';
  src: url('/public/fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('/public/fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('/public/fonts/Nunito-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


/* Custom class for FuseSoul text */
.fuseSoul-font {
  font-family: 'Nunito', sans-serif;
  font-style: normal;
}

/* Landing Page Navigation Styles */
.nav-container {
  @apply w-full bg-white border-b border-gray-300 fixed top-0 left-0 right-0 z-50;
}

.nav-content {
  @apply container mx-auto flex justify-between items-center p-4;
}

.nav-logo {
  @apply flex items-center text-2xl font-bold fuseSoul-font text-primary-color;
}

.nav-logo img {
  @apply h-8 w-8 mr-2;
}

.nav-menu {
  @apply flex items-center space-x-9;
}

.nav-link {
  @apply text-primary-color hover:text-secondary-color;
}

/* Mobile menu styles */
.mobile-menu-overlay {
  @apply fixed inset-0 bg-black transition-opacity duration-300 ease-in-out;
}

.mobile-menu-overlay.open {
  @apply opacity-50 z-40;
}

.mobile-menu-overlay.closed {
  @apply opacity-0 z-[-1];
  pointer-events: none;
}

.mobile-menu {
  @apply fixed top-0 right-0 h-full w-72 bg-white z-50 shadow-lg transform transition-transform duration-300 ease-in-out;
}

.mobile-menu.open {
  @apply translate-x-0;
}

.mobile-menu.closed {
  @apply translate-x-full;
}

.mobile-menu-item {
  @apply block px-6 py-4 text-primary-color hover:bg-soft-light-blue border-b border-gray-200;
}

.mobile-menu-header {
  @apply flex justify-between items-center p-6 border-b border-gray-200;
}

.mobile-menu-title {
  @apply text-xl font-bold text-primary-color;
}

.mobile-menu-close {
  @apply text-primary-color;
}

/* Update button styles for landing page */
.button-landing {
  @apply text-white px-4 py-2 rounded text-sm font-semibold transition duration-300 ease-in-out;
  background: linear-gradient(to right, var(--secondary-color), var(--primary-color));
}

.button-landing:hover {
  opacity: 0.9;
}

.backdrop-blur-overlay {
  position: fixed;
  inset: 0; /* shorthand for top: 0; right: 0; bottom: 0; left: 0; */
  z-index: 50; /* Adjust to suit your app's stacking needs */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  backdrop-filter: blur(8px); /* Blurs everything behind the overlay */
  -webkit-backdrop-filter: blur(8px); /* Safari support */
}

/* App Container Styles */
.App-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;
}

/* ======================================
   INSPIRATIONAL PAGE SPECIFIC STYLES 
   ====================================== */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 262.1 83.3% 57.8%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 40% 98%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 263.4 70% 50.4%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  /* Cloud animation components for the Inspiration Page */
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .cloud-shape {
    position: relative;
    width: 100px;
    height: 40px;
    background: white;
    border-radius: 50px;
    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.1));
    transform-origin: center center;
    animation: float-cloud 8s infinite ease-in-out;
  }

  .cloud-shape:before,
  .cloud-shape:after {
    content: "";
    position: absolute;
    background: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: -20px;
  }

  .cloud-shape:before {
    left: 15px;
  }

  .cloud-shape:after {
    right: 15px;
  }

  @keyframes float-cloud {
    0%,
    100% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-10px) scale(1.05);
    }
  }

  /* Add this new cloud shape class for more beautiful clouds */
  .cloud-shape-beautiful {
    position: relative;
    background: white;
    border-radius: 50px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
  }

  .cloud-shape-beautiful:before,
  .cloud-shape-beautiful:after {
    content: "";
    position: absolute;
    background: white;
    border-radius: 50%;
  }

  .cloud-shape-beautiful:before {
    width: 50%;
    height: 100%;
    top: -40%;
    left: 25%;
  }

  .cloud-shape-beautiful:after {
    width: 60%;
    height: 80%;
    top: -15%;
    right: 15%;
  }

  @keyframes float-cloud-beautiful {
    0%,
    100% {
      transform: translateY(0) scale(1) rotate(0deg);
    }
    25% {
      transform: translateY(-5px) scale(1.02) rotate(0.5deg);
    }
    50% {
      transform: translateY(-10px) scale(1.05) rotate(0deg);
    }
    75% {
      transform: translateY(-5px) scale(1.02) rotate(-0.5deg);
    }
  }

  /* Add a simpler cloud class that will definitely work */
  .simple-cloud {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .simple-cloud:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    border-radius: 50px;
  }

  .simple-cloud:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 50%;
    top: -20%;
    left: 25%;
    background: white;
    border-radius: 50%;
    box-shadow: 40% -10% 0 10% white;
  }
}

/* Add shadcn/ui base utility classes */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.5% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Animation for twinkling stars in meditation full-page mode */
@keyframes twinkle {
  0% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
}

/* Styled container for dialogs (like delete confirmation) */
.dialog-container-styled {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  width: auto !important;
  min-width: 300px !important;
  max-width: 90vw !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}
