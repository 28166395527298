/* mobile-touch.css - Enhanced touch interactions for mobile devices */

/* Increase touch targets for better mobile experience */
@media (max-width: 768px) {
  /* Make all buttons more touch-friendly but preserve global styling */
  button,
  .MuiButton-root,
  .MuiIconButton-root,
  a.MuiButtonBase-root {
    min-height: 44px;
    min-width: 44px;
  }

  /* Increase spacing between clickable elements */
  .MuiListItem-root,
  .MuiMenuItem-root,
  .MuiTab-root {
    padding: 12px 16px;
  }

  /* Make form controls easier to tap */
  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiSelect-root,
  .MuiCheckbox-root,
  .MuiRadio-root,
  .MuiSwitch-root {
    margin: 8px 0;
  }

  /* Increase font size for better readability */
  input, 
  textarea, 
  select {
    font-size: 16px; /* Prevents iOS zoom on focus */
  }

  /* Add active state feedback for touch interactions */
  button:active,
  .MuiButton-root:active,
  .MuiIconButton-root:active,
  a:active {
    transform: scale(0.97);
    transition: transform 0.1s ease-in-out;
  }

  /* Improve scrolling experience */
  * {
    -webkit-overflow-scrolling: touch;
  }

  /* Add pull-to-refresh visual indicator */
  .pull-to-refresh-indicator {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 14px;
  }

  /* Prevent text selection on interactive elements */
  button, 
  .MuiButton-root, 
  .MuiIconButton-root,
  a.MuiButtonBase-root,
  .MuiListItem-root {
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  /* Improve tap feedback */
  .tap-highlight {
    position: relative;
    overflow: hidden;
  }

  .tap-highlight::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 0.5s;
  }

  .tap-highlight:active::after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }

  /* Fix for iOS 100vh issue */
  .full-height {
    height: 100vh;
    height: -webkit-fill-available;
    height: stretch;
  }

  /* Bottom navigation spacing for devices with home indicators */
  .bottom-nav-spacer {
    height: env(safe-area-inset-bottom, 0);
    width: 100%;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  /* Further increase touch targets for very small screens */
  button,
  .MuiButton-root,
  .MuiIconButton-root {
    min-height: 48px;
  }

  /* Increase spacing between elements */
  .MuiListItem-root,
  .MuiMenuItem-root {
    padding: 14px 16px;
  }
}

/* Landscape orientation adjustments */
@media (max-width: 900px) and (orientation: landscape) {
  /* Adjust layout for landscape mode */
  .landscape-adjust {
    flex-direction: row !important;
  }

  /* Make content scrollable in landscape */
  .landscape-scroll {
    max-height: 80vh;
    overflow-y: auto;
  }
} 